import { Heading } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Wrapper = styled.div`
	margin-top: '10.7rem';
`;

const ContentWrapper = styled.div`
	height: 100%;
	max-width: 780px;
	padding: 20px;
	margin: 0 auto;
	background: #fff;
	box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 13%);
	border-radius: 6px;
	position: relative;
	bottom: 120px;

	@media ${(props) => props.theme.breakpoint.mobile} {
		padding-top: 25px;
	}

	ul {
		columns: 2 auto;
		color: slategray;
		line-height: 0;
	}

	li {
		line-height: 20px;
		text-align: center;
		padding-bottom: 32px;
		img {
			margin: 0 auto;
			padding-bottom: 25px;
		}
	}

`;

const SunbrellaBrand = ({ data }) => {
	const brand = data.brands.nodes[0];
	const urlPart = JSON.parse(brand.oembed)?.url.split('=')[1];
	const videoSrc = `https://www.youtube.com/embed/${urlPart}`;

	return (
		<Wrapper>
			<StaticImage
				src="../images/dsc9240-1024x683.jpg"
				alt="Spradling"
				style={{ height: '450px', width: '100%' }}
			/>
			<ContentWrapper>
					<Heading as="h1" size="xl" align="center" mb={20}>
						{brand.title}
					</Heading>
					<ReactMarkdown
						className="line-break"
						transformImageUri={(uri) =>
							uri.startsWith('http')
								? uri
								: `${process.env.BASE_URL}${uri.replace('uploads', 'static')}`
						}
					>
						{brand.content}
					</ReactMarkdown>
					<iframe
						style={{ marginTop: '50px', width: '100%' }}
						height="350"
						src={videoSrc}
						frameBorder="0"
						allowFullScreen
					></iframe>
			</ContentWrapper>
		</Wrapper>
	);
};

export const query = graphql`
	query ($lang: String!, $slug: String!) {
		brands: allStrapiBrands(
			filter: { locale: { eq: $lang }, slug: { eq: $slug } }
		) {
			nodes {
				...brandFields
				oembed
			}
		}
	}
`;

export default SunbrellaBrand;
